import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import Button from '@mui/material/Button';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-evenly',
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(1),
    padding: theme.spacing(0, 1),
    '&:not(:first-of-type)': {
      border: '1px solid black',
      borderRadius: '50%',
      marginLeft: '0px',
    },
    '&:first-of-type': {
      border: '1px solid black',
      borderRadius: '50%',
    },
    '&.Mui-selected': {
      border: '1px solid black !important',
    },
    '&.Mui-disabled': {
      color: 'black',
      opacity: '40%',
    },
  },
}));

const StyledToggle = styled(ToggleButton)(() => ({
  color: 'black',
  backgroundColor: 'white',
  width: '13%',
  '&.Mui-selected': {
    color: 'white',
    background: '#3b78e7',
    '&:hover': {
      background: '#618ee1',
      color: 'black',
    },
  },
  '&:hover': {
    background: '#d7e4fa',
  },
  minWidth: 32,
  maxWidth: 32,
  height: 32,
  textTransform: 'unset',
  fontSize: '0.75rem',
}));

const convertArrayToDates = (numArray, days) =>
  numArray.map((num) => days[num].formattedDate);
const convertDatesToArray = (dateArray, days) => {
  if (dateArray.length === 0 || days.length === 0) return [];
  const formattedDates = days.map((day) => day.formattedDate);
  return dateArray
    .map((date) => formattedDates.indexOf(date))
    .filter((date) => date > -1);
};

const InputWeekdays = (props) => {
  const {
    value,
    onChange,
    currentDate,
    weekStart,
    componentWidth,
    format,
    name,
    disabled,
    hidden,
    currentIndex,
    hideWeekChanger,
  } = props;

  const hiddenVal = (() => {
    if (typeof hidden === 'function') return hidden(currentIndex);
    if (typeof hidden === 'boolean') return hidden;
    return false;
  })();


  const [days, setDays] = useState([]);

  const calculateDays = (initialDate) => {
    const dayNameList = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const startDate = dayjs(initialDate);
    const firstDay = (() => {
      if (startDate.day() === 0 && weekStart === 1) {
        return startDate.subtract(6, 'day');
      }
      return startDate.subtract(startDate.day() - weekStart, 'day');
    })();
    const dayArray = [];
    for (let i = 0; i < 7; i++) {
      dayArray.push({
        key: dayNameList[(weekStart + i) % 7],
        label: dayNameList[(weekStart + i) % 7].substring(0, 1),
        value: firstDay.add(i, 'day'),
        formattedDate: firstDay.add(i, 'day').format(format),
        day: firstDay.add(i, 'day').date(),
      });
    }
    setDays(dayArray);
  };

  useEffect(() => {
    calculateDays(currentDate, setDays, weekStart);
  }, []);
  const WeekStepper = useCallback(
    () => (
      <>
        <Button
          disableRipple
          disabled={disabled}
          style={{
            backgroundColor: 'transparent',
            minWidth: '50px',
            maxWidth: '50px',
            width: '50px',
            color: 'gray',
          }}
          onClick={() => {
            if (value.length > 0) onChange([], name);
            calculateDays(days[0].value.subtract(7, 'day'), setDays, weekStart);
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Button
          disableRipple
          disabled={disabled}
          style={{
            backgroundColor: 'transparent',
            minWidth: '50px',
            maxWidth: '50px',
            width: '50px',
            color: 'gray',
            justifyContent: 'right',
          }}
          onClick={() => {
            if (value.length > 0) onChange([], name);
            calculateDays(days[0].value.add(7, 'day'), setDays, weekStart);
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </>
    ),
    [days, value, disabled]
  );
  return (
    <StyledEngineProvider injectFirst>
      <div
        style={{
          backgroundColor: 'white',
          width: componentWidth,
          display: hiddenVal ? 'none' : 'block',
        }}
      >
        <div style={{ display: 'flex', padding: '8px 12px', width: '100%' }}>
          <div
            style={{ width: '100%', textAlign: 'left', alignSelf: 'center' }}
          >
            {days.length > 0 &&
              [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ][days[0].value.month()]}{' '}
            - {days.length > 0 && days[0].value.year()}
          </div>
          <div style={{ width: '100%', textAlign: 'right' }}>
            {!hideWeekChanger && <WeekStepper />}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {days.map((day) => (
            <div key={day.key} style={{ width: '32px', textAlign: 'center' }}>
              {day.label}
            </div>
          ))}
        </div>
        <div style={{ width: '100%' }}>
          <StyledToggleButtonGroup
            size="small"
            disabled={disabled}
            arial-label="Days of the week"
            value={convertDatesToArray(value, days)}
            onChange={(_event, v) => {
              onChange(convertArrayToDates(v, days), name);
            }}
          >
            {days.map((day, index) => (
              <StyledToggle
                key={day.key}
                value={index}
                aria-label={day.key}
                name={name}
              >
                {day.day}
              </StyledToggle>
            ))}
          </StyledToggleButtonGroup>
        </div>
      </div>
    </StyledEngineProvider>
  );
};

InputWeekdays.propTypes = {
  /** Weekday values. Used for the Transaction value */
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Application OnChange Function that updates the transaction with the value */
  onChange: PropTypes.func.isRequired,
  /** Form Field name. Used for the Transaction key */
  name: PropTypes.string.isRequired,
  /** Initial date displayed. */
  currentDate: PropTypes.string,
  /** Day of the week displayed on the far left. Accepted values range from 0 (Sunday) to 6 (Saturday). */
  weekStart: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  /** Component width. Accepts css style string. */
  componentWidth: PropTypes.string,
  /** Dayjs date format. */
  format: PropTypes.string,
  /** Disables Form Field */
  disabled: PropTypes.bool,
  /** Hides/Shows the Form Field */
  hidden: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  /** Comes from Repeatable List that exposes the index that the object is working off of. DO NOT USE. */
  currentIndex: PropTypes.number,
  /** Hides the buttons to prevent week skips */
  hideWeekChanger: PropTypes.bool,
};
InputWeekdays.defaultProps = {
  currentDate: dayjs().format('YYYY-MM-DD'),
  weekStart: 0,
  componentWidth: '310px',
  format: 'YYYY-MM-DD',
  disabled: false,
  hidden: false,
  currentIndex: undefined,
  hideWeekChanger: false,
};

export default InputWeekdays;
