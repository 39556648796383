import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Drawer, BottomNavigationAction, BottomNavigation, Button, useMediaQuery, useTheme, Dialog } from '@mui/material';
import { faBars, faCopyright } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSetRecoilState } from 'recoil';
import SidebarData from './Menu/SideBarData';
import { UserContext } from '../providers/UserProvider';
import DashboardSidebar from '../components/dashboard/DashboardSidebar';
import './footer.css';
import { _ShowChangelogModal } from '../_Recoil/atoms';

const ConfidentialityModal = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}> Unless otherwise indicated or obvious from the nature of the application, the information contained in this application is attorney privileged and confidential information intended for the use of authorized individuals or entities only. If the viewer of this content is not correctly authorized, you are hereby notified that any dissemination, distribution or copying of this content is strictly prohibited. </Dialog>
      <button type="button" className="button" onClick={() => setOpen(true)}>
        <i>Click here to review the confidentiality statement.</i>
      </button>
    </>
  );
};


const Footer = (props) => {
  const { version, copy } = props;
  const [footerOpen, setFooterOpen] = useState(false);
  const [opening, setOpening] = useState(0);
  const [closing, setClosing] = useState(0);
  const [value, setValue] = React.useState(0);
  const match = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matches2 = useMediaQuery(theme.breakpoints.down('xs'));
  const SetShowChangelogModal = useSetRecoilState(_ShowChangelogModal);
  const user = useContext(UserContext);
  const [drawer, setDrawer] = React.useState(false);
  const [keyboardHide, setKeyboardHide] = React.useState(false);
  const pastHeight = window.innerHeight;

  const onClick = () => {
    setFooterOpen(!footerOpen);
    if (footerOpen && (!matches || (user && user.payroll)))
      setOpening(1);
    else if (!matches || (user && user.payroll))
      setClosing(1);
  };
  const findBottom = () => {
    if ((!footerOpen) && (!matches || (user && user.payroll)))
      return '0px';
    return '55px';
  };

  const onLinkClick = () => {
    localStorage.removeItem('ChangelogShown');
    SetShowChangelogModal(true);
  };
  useEffect(() => {
    let mounted = true;
    const handleKeyboard = () => {
      if (matches2) {
        const currentHeight = window.innerHeight;
        if (pastHeight > currentHeight && mounted) { setKeyboardHide(true); } else if (mounted) { setKeyboardHide(false); };
      }
    };
    window.addEventListener('resize', handleKeyboard);
    return () => (mounted = false);
  });
  useEffect(() => {
    let mounted = true;
    const first = match.pathname.split('/')[1].toLowerCase();
    if (mounted) {
      switch (first) {
        case 'pod':
          setValue(1);
          break;
        case 'time-entry':
          setValue(2);
          break;
        case 'daily-report':
          setValue(4);
          break;
        default:
          setValue(9);
          break;
      }
    }
    return () => (mounted = false);
  }, [match]);
  return (
    <>
      {user && !keyboardHide ?
        <Button className="icon" disableRipple style={{
          position: 'fixed',
          bottom: findBottom(),
          right: matches2 ? '5px' : '15px',
          zIndex: '1000',
          backgroundColor: 'transparent',
          maxHeight: '25px',
          minWidth: '25px',
          maxWidth: '25px'
        }} onClick={onClick}
          onAnimationEnd={() => { setOpening(0); setClosing(0); }}
          opening={opening}
          closing={closing}>
          {
            footerOpen ? <FontAwesomeIcon justifycontent='right' style={{ color: 'black' }} icon={faCopyright} /> : <FontAwesomeIcon style={{ color: 'black' }} justifycontent='right' icon={faCopyright} />}</Button> : null}
      {matches && user && !keyboardHide && (user && !user.payroll) ?
        <BottomNavigation style={{ width: '100%', backgroundColor: 'rgb(52, 58, 64)' }} hidden={keyboardHide} value={value}
          showLabels>
          {SidebarData.map(item => item.dock === true ? (
            <BottomNavigationAction
              label={item.title}
              icon={<FontAwesomeIcon className="aldg-ui-icon fa-fw" icon={item.icon} />}
              className='MuiBottomNavigationAction'
              component={Link}
              to={item.href}
              hidden={item.dock}
              key={item.href}
            />
          ) : null)}
          <BottomNavigationAction
            label='More'
            icon={<FontAwesomeIcon className="aldg-ui-icon fa-fw" icon={faBars} />}
            className='MuiBottomNavigationAction'
            onClick={() => { setDrawer(true); }}
            key='More'
          />
        </BottomNavigation> : null}

      <Drawer
        anchor='right'
        onClose={() => setDrawer(false)}
        open={drawer}
        variant='temporary'
        PaperProps={{
          sx: {
            width: 256
          }
        }}
      >
        <DashboardSidebar setApplicationDrawer={() => setDrawer(false)} />
      </Drawer>
      <Drawer variant='persistent' anchor='bottom' open={footerOpen && !keyboardHide} onClose={() => setFooterOpen(false)}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          backgroundColor: '#343a40',
          padding: '3px',
          color: '#fff',
          zIndex: '2500'
        }}
        >

          <div>Copyright &copy; {copy}</div>
          <ConfidentialityModal />
          <div>v {version}
            <button type="button" className="button" onClick={onLinkClick}> <u> Update Notes</u></button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

Footer.propTypes = {
  version: PropTypes.string,
  copy: PropTypes.string
};
Footer.defaultProps = {
  version: '0.0.0',
  copy: 'Aldridge, All Rights Reserved'
};

export default Footer;