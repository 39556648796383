import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { onSnapshot, collection, query, where, orderBy } from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { _CurrentForeman } from '../../_Recoil/atoms';
import { firestore } from '../../firebase';


const CrewDashboard = () => {
  const [Crew, setCrew] = useState([]);
  const CurrentForeman = useRecoilValue(_CurrentForeman);

  useEffect(() => {
    let mounted = true;
    if (CurrentForeman.value.length > 0) {
      const q = query(
        collection(firestore, 'Crew-Manager'),
        where('data.Foreman', '==', CurrentForeman.value[0]),
        orderBy('data.EmployeeDisplay'));
      onSnapshot(q, (snap) => {
        const all = [];
        snap.forEach(doc => {
          all.push(doc.data());
        });
        if (mounted) {

          setCrew(all);
        }
      });
    } else {
      setCrew([]);
    }
    return () => (mounted = false);

  }, [CurrentForeman]);

  const columns = [
    {
      field: 'data.EmployeeDisplay',
      headerName: 'Employee',
      width: 200,
      valueGetter: (params) => params.row.data.EmployeeDisplay || ''
    }
  ];

  return (
    <AldgDataGrid rows={Crew} columns={columns} containerHeight="600px" />
  );
};


export default CrewDashboard;