/* eslint-disable no-unused-vars */
import { colors } from '@mui/material';
import { createTheme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { blueGrey, green, grey, red } from '@mui/material/colors';
import shadows from './shadows';
import typography from './typography';

const AldgTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.aldg-ui-iconContainer': {
            margin: '0 12px',
            boxShadow:
              '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            width: '40px',
            height: '40px',
            minWidth: '0',
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            backgroundColor: grey[400],
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: grey[600],
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
            }
          },
          '&.aldg-ui-errorContainer': {
            backgroundColor: red[400],
            '&:hover': {
              backgroundColor: red[600]
            }
          },
          '&.aldg-ui-primaryContainer': {
            backgroundColor: '#3f51b5',
            '&:hover': {
              backgroundColor: '#26389C'
            }
          },
          '&.aldg-ui-grayContainer': {
            backgroundColor: grey[500],
            '&:hover': {
              backgroundColor: grey[700]
            }
          },
          '&.aldg-ui-successContainer': {
            backgroundColor: green[400],
            '&:hover': {
              backgroundColor: green[600]
            }
          },
          '&.aldg-ui-secondaryContainer': {
            backgroundColor: '#f50057',
            '&:hover': {
              backgroundColor: '#DC003E'
            }
          },
          '&.aldg-ui-loadingContainer': {
            backgroundColor: grey[300],
            opacity: '70%'
          },
          '&.aldg-ui-buttonIconWrapper': {
            padding: '8px',
            minHeight: '40px',
            maxHeight: '40px',
            margin: '0 12px'
          },
          '& .aldg-ui-icon': {
            color: 'white'
          },
          '& .MuiButton-endIcon': {
            marginRight: '2px',
            marginLeft: '10px'
          },
          '& .MuiButton-startIcon': {
            marginRight: '10px',
            marginLeft: '2px'
          },
          '&.filterBtn': {
            textTransform: 'none',
            color: '#3f51b5',
            backgroundColor: 'white',
            borderRadius: '0px',
            whiteSpace: 'nowrap',
            maxHeight: '40px'
          },
          '&.filterBtnSelected': {
            textTransform: 'none',
            borderRadius: '0px',
            backgroundColor: '#3f51b5 !important',
            color: 'white',
            maxHeight: '40px',
            whiteSpace: 'nowrap',
            '&:hover': {
              backgroundColor: '#132cb6'
            }
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.aldg-ui-buttonText': {
            textTransform: 'none'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-container': {
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            '& .MuiDialog-paper': {
              width: '50%'
            }
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 0px',
          justifyContent: 'space-between'
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .modalPaper': {
            zIndex: 1,
            backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: shadows[5],
            outline: 'none',
            padding: '8px',
            minHeight: '50%',
            maxHeight: '95%',
            overflow: 'auto',
            width: '70%'
          },
          '& .timePaper': {
            zIndex: 1,
            backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: shadows[5],
            width: '70%',
            padding: '8px',
            maxHeight: '95%',
            overflowY: 'auto'
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        '& .gridItem': {
          padding: '5px',
          flexBasis: '0%'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          '&:not(:last-child)': {
            borderBottom: 0
          },
          '&.Mui-expanded': {
            border: '1px solid #2a56a7',
            margin: '8px 0',
            '& .MuiAccordionSummary-expandIcon': {
              color: 'white'
            }
          }
        },
        expanded: {}
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        outlined: {},
        root: {
          backgroundColor: '#eee',
          boxShadow: 'inset 0px -19px 4px -19px #aaa',
          color: 'inherit',
          maxHeight: '56px',
          minHeight: '48px',
          '& .MuiButtonBase-root': {
            justifyContent: 'flex-start',
            padding: '2px 6px',
            display: 'flex',
            alignItems: 'center',
            color: 'black',
            border: '2px solid #444'
          },
          '&.Mui-expanded': {
            color: 'white',
            maxHeight: '48px',
            minHeight: '48px',
            backgroundColor: 'rgb(59, 120, 231) !important',
            '& .MuiButtonBase-root': {
              border: '2px solid white',
              color: 'white'
            }
          },
          editIcon: {
            color: 'black'
          },
          expanded: {}
        },
        expanded: {}
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          flexDirection: 'column'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-footerContainer': {
            justifyContent: 'space-between',
            marginLeft: '-12px',
            border: 'none'
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: '#efefef'
          },
          '& .MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-columnHeader': {
            height: '55px !important'
          },
          '&.aldg-doubleBorder': {
            '& .MuiDataGrid-main': {
              '& .MuiDataGrid-columnHeaders': {
                borderTop: 'double black',
                borderBottom: 'double black',
                borderRadius: 0,
                minHeight: '55px !important',
                maxHeight: '55px !important',
                lineHeight: '55px !important'
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                textAlign: 'center',
                whiteSpace: 'pre-wrap !important',
                lineHeight: '16px',
                fontWeight: 'bold'
              }
            },
            border: 'none',
            outline: '1px solid rgba(224, 224, 224, 1)'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#3f51b5',
      dark: '#26389C'
    },
    secondary: {
      main: '#f50057',
      dark: '#DC003E'
    },
    info: {
      main: '#e0e0e0'
    }
  },
  shadows,
  typography
});

export default AldgTheme;
