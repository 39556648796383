import React from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavItem = ({ href, icon: Icon, title, onClick }) => {
  const location = useLocation();
  const active = href
    ? !!matchPath(
      {
        path: href,
        end: false
      },
      location.pathname
    )
    : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        width: '100%',
        py: 0
      }}
    >
      {Icon && <FontAwesomeIcon className='aldg-ui-icon' style={{ width: '1.5em', marginLeft: '-8px' }} icon={Icon} />}
      <Button
        component={RouterLink}
        onClick={onClick}
        style={{ width: '100%', justifyContent: 'flex-start' }}
        sx={{
          color: 'text.secondary',
          fontWeight: 'medium',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'primary.main'
          }),
          '& svg': {
            mr: 1
          }
        }}
        to={href}
      >
        <span>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

NavItem.defaultProps = {
  onClick: () => { }
};


export default NavItem;
