import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { _CurrentForeman, _GroupName } from '../../_Recoil/atoms';
import { firestore } from '../../firebase';


const GroupDashboard = () => {
  const [Groups, setGroupName] = useState([]);
  const GroupName = useRecoilValue(_GroupName);
  const Foreman = useRecoilValue(_CurrentForeman);

  useEffect(() => {
    let mounted = true;
    if (Foreman.value.length === 0) {
      toast.error('Please select a Manager.');
      return () => (mounted = false);
    }
    const q = query(
      collection(firestore, 'Group-Manager'), where('data.Foreman', '==', Foreman.value[0]));
    onSnapshot(q, (snap) => {
      const all = [];
      snap.forEach(doc => {
        all.push(doc.data());
      });
      if (mounted) {
        setGroupName(all);
      }
    });

    return () => (mounted = false);

  }, [GroupName]);

  const columns = [
    {
      field: 'data.GroupName',
      headerName: 'Groups',
      width: 200,
      valueGetter: (params) => params.row.data.GroupName || ''
    }
  ];

  return (
    <AldgDataGrid rows={Groups} columns={columns} />
  );
};

export default GroupDashboard;