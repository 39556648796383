import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import {
  CrewList,
  CrewListSelection
} from '../components/crew/CrewListSelection';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import { _HeaderTitle } from '../_Recoil/atoms';
import ErrorBoundary from '../utils/ErrorBoundary';

const Crew = (props) => {
  const { match, history } = props;
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  document.title = 'Crew';
  useEffect(() => setHeaderTitle('Daily Crew'), []);
  return (
    <ErrorBoundary componentName='Crew'>
      <Grid className='gridItem'>
        <Grid item xs={12} className='gridItem'>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        <Grid container>
          <DailyToolbar />
        </Grid>
        {typeof match.params.id === 'undefined' ? (
          <>
            <CrewList history={history} match={match} />
          </>
        ) : (
          <CrewListSelection history={history} match={match} />
        )}
      </Grid>
    </ErrorBoundary>
  );
};

Crew.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
Crew.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default Crew;
