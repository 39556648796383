import dayjs from 'dayjs';

const weekEndDate = (dt) => {
  try {
    // Create a copy of the date to avoid modifying the original
    const currentDate = new Date(dt);
    // Get the current day of the week (0 for Sunday, 1 for Monday, ...)
    const currentDayOfWeek = currentDate.getDay();
    // Calculate the difference between the current day and the next Sunday
    const daysToAdd = (7 - currentDayOfWeek) % 7;
    // Add the calculated days to the current date
    currentDate.setDate(
      currentDate.getDate() + (daysToAdd === 0 ? 7 : daysToAdd)
    );
    return dayjs(currentDate).format('YYYY-MM-DD');
  } catch (err) {
    return 'Invalid Date';
  }
};

const dayDifference = (dt) => {
  const wkdt = new Date(dt);
  const fixedDate = new Date(wkdt.getTime() + wkdt.getTimezoneOffset() * 60000);
  const today = new Date();

  return Math.round(
    (today.getTime() - fixedDate.getTime()) / (1000 * 3600 * 24)
  );
};

export { weekEndDate, dayDifference };
