/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */

import React from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { collection, doc, setDoc } from 'firebase/firestore';
import SubmitHelpDeskTicket from './SubmitHelpDeskTicket';
import * as serviceWorker from '../serviceWorkerRegistration';
import { functions, auth, firestore } from '../firebase';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, data: null, fError: null };

    this.firebaseCall = this.firebaseCall.bind(this);
    this.sendTheMail = this.sendTheMail.bind(this);
  }

  componentDidCatch(e, ei) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: e,
      errorInfo: ei
    });
    // You can also log error messages to an error reporting service here
    const { componentName, user } = this.props;
    this.sendTheMail(componentName, ei.componentStack, e);
    const analytics = getAnalytics();
    logEvent(analytics, 'page_render_error', {
      pageWithError: componentName,
      errorDetailInfo: ei.componentStack,
      errorLogged: JSON.parse(JSON.stringify(e))
    });
    const d = {
      pageWithError: componentName,
      user: user.email,
      errorDetailInfo: ei.componentStack,
      DJRKey: JSON.parse(JSON.stringify(localStorage.getItem('DJR-KEY'))),
      errorLogged: JSON.parse(JSON.stringify(e)),
      date: new Date().toJSON()
    };
    const docRef = doc(collection(firestore, 'PageRenderError'));
    d.id = docRef.id;
    setDoc(docRef, d);
  }

  firebaseCall(body) {
    const firebaseFunction = httpsCallable(functions, 'SendEmail');
    this.setState({ data: null, fError: null });
    const dataCall = (b) => {
      try {
        auth.currentUser
          .getIdToken(true)
          .then((idToken) => {
            const opts = {
              body: b,
              idToken,
              host: window.location.hostname
            };
            firebaseFunction(opts)
              .then((res) => {
                switch (res.data.status) {
                  case 'Success':
                    this.setState({ data: res.data.message });
                    break;
                  case 'Error':
                    this.setState({ fError: res.data.message });
                    break;
                  default:
                    this.setState({ fError: JSON.stringify(res) });
                    break;
                }
              })
              .catch((err) => {
                this.setState({ fError: err.message });
              });
          })
          .catch((err) => {
            this.setState({ fError: err.message });
          });
      } catch (err) {
        // eslint-disable-next-line
        console.log('Failed to send email.');
      }
    };
    dataCall(body);
    return this.firebaseCall;
  }

  sendTheMail(cName, eStack, err) {
    if (window.location.hostname === 'localhost') return;
    const sendEmail = this.firebaseCall;
    sendEmail({
      to: ['developers@aldridgegroup.com'],
      subject: 'Page Render Error',
      html: `<h1>${cName} has crashed :/</h1><h2>${err}</h2> <p>${eStack}</p>`
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    const fRefreshApplication = () => {
      serviceWorker.unregister().then(() => {
        localStorage.removeItem('DJR-KEY');
        window.location.reload();
      });
    };
    if (error) {
      // Error path
      return (
        <div>
          <h1>Something went wrong.</h1>
          {/* <span className="fa-layers fa-fw" style={{ width: '5em', height: '100%', position: 'relative' }}>
            <FontAwesomeIcon icon={faCog} style={{ fontSize: '4em' }} />
            <FontAwesomeIcon icon={faCog} style={{ color: 'red', position: 'absolute', top: '-40px', left: '64px', fontSize: '2.5em' }} pulse />
          </span> */}
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <br />
            {error && error.toString()}
            <h3 style={{ marginTop: '16px' }}>Keep seeing this? </h3>
            <div style={{ padding: '8px' }}>
              <Button
                color='primary'
                variant='contained'
                onClick={fRefreshApplication}
              >
                Force Refresh Application
              </Button>
            </div>
            <h4>Or submit a help desk ticket.</h4>
            <div style={{ padding: '8px' }}>
              <SubmitHelpDeskTicket />
            </div>
          </details>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  componentName: PropTypes.string.isRequired,
  user: PropTypes.objectOf(PropTypes.any)
};

ErrorBoundary.defaultProps = {
  user: { email: 'none' }
};

export default ErrorBoundary;
