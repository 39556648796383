/* eslint-disable no-unused-vars */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { toast } from 'react-toastify';
import { weekEndDate } from './dateFunctions';

const checkPOD = (user, record) => {
  if (user && user.admin) return false;
  const lastDayToEdit = dayjs().subtract('5', 'day').format('YYYY-MM-DD');
  if (lastDayToEdit >= record.data.Date && record.data.Date !== '') {
    toast.info(
      `I'm sorry, you can no longer add/modify Tasks for "${record.data.Date}".`
    );
    return true;
  }
  return false;
};

const checkTime = (user, CurrentDate, returnForcedCutoff) => {
  if (returnForcedCutoff) {
    return undefined;
  }
  // if (user && user.admin) return false;
  const today = dayjs().format('YYYY-MM-DD HH:mm');
  const day = dayjs(today).day();
  const hour = dayjs(today).hour();
  const minTimeDate =
    // !(day >= 2 && hour >= 0)
    // !(day >= 2 && hour >= 13)
    !(day >= 2 && hour >= 13)
      ? dayjs(today)
          .subtract(7 + (day - 1), 'days')
          .format('YYYY-MM-DD')
      : dayjs(today)
          .subtract(day === 0 ? 6 : day - 1, 'days')
          .format('YYYY-MM-DD');
  const dateDiff = dayjs(CurrentDate.value).diff(minTimeDate);
  const dayDiff = Math.ceil(dateDiff / (1000 * 3600 * 24));

  if (dayDiff < 0) {
    if (!toast.isActive('timeLockout')) {
      toast.error('You can no longer edit time for this date.', {
        toastId: 'timeLockout'
      });
    }
    return true;
  }
  return false;
};

// const checkTime = (user, CurrentDate, returnForcedCutoff = false) => {
//   const forcedCutOffDate = '2024-12-23';
//   const forcedCutOffTime = '08:45';

//   dayjs.extend(utc);
//   dayjs.extend(timezone);
//   dayjs.extend(localizedFormat);

//   const cutOffDate = dayjs.tz(
//     `${forcedCutOffDate} ${forcedCutOffTime}`,
//     'YYYY-MM-DD HH:mm',
//     'America/Chicago'
//   );
//   // const val = '2024-12-31 08:45';
//   const now = dayjs();
//   if (returnForcedCutoff) {
//     return cutOffDate;
//   }

//   const dateDiff = cutOffDate.diff(now);
//   let currDiff = dayjs(CurrentDate.value).diff(now);
//   const forcedWeekEndDate = weekEndDate(forcedCutOffDate);
//   const currentWeekEndDate = weekEndDate(CurrentDate.value);

//   if (forcedWeekEndDate === currentWeekEndDate) {
//     currDiff = 1;
//   }
//   if (
//     (dateDiff <= 0 && currDiff <= 0) ||
//     dayjs(forcedCutOffDate).diff(currentWeekEndDate, 'week') > 0
//   ) {
//     if (!toast.isActive('timeLockout')) {
//       toast.error('You can no longer edit time for this date.', {
//         toastId: 'timeLockout'
//       });
//     }
//     return true;
//   }
//   return false;
// };

export { checkPOD, checkTime };
