import { RecoilRoot } from 'recoil';

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Loader from 'react-loader-spinner';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import './_assets/base.scss';
import 'react-toastify/dist/ReactToastify.css';

import { OfflineProvider } from '@aldridge/aldg-helpers';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import Header from './_GlobalComponents/Header';
import Footer from './_GlobalComponents/Footer';
import Application from './Pages/Application';
import UserProvider from './providers/UserProvider';
import OfflineDataProvider from './providers/OfflineDataProvider';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { config } from './firebase';
import ChangelogModal from './ChangelogModal';
import AldgTheme from './theme';

const rootElement = document.getElementById('root');
const root = createRoot(document.getElementById('root'));


const renderApp = () => {
  const configClass = `${config.projectId.indexOf('-dev') > -1 ? 'bg-dev' : ''
    } ${config.projectId.indexOf('-training') > -1 ? 'bg-training' : ''}`.trim();
  try {
    root.render(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={AldgTheme}>
          <RecoilRoot>
            <OfflineProvider>
              <UserProvider>
                <OfflineDataProvider>
                  <BrowserRouter>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ToastContainer
                        position='bottom-left'
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                      <Suspense
                        fallback={
                          <div className='loader-container'>
                            <div className='loader-container-inner'>
                              <div className='text-center'>
                                <Loader type='TailSpin' />
                              </div>
                              <h6 className='mt-3'>
                                Please wait while we load the Application
                              </h6>
                            </div>
                          </div>
                        }
                      >
                        <div
                          id='appParent'
                          className={configClass}
                          style={{
                            display: 'flex',
                            height: '100%',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                          }}
                        >
                          <ChangelogModal />
                          <Header />
                          <Application />
                          <Footer
                            version='2.6.3'
                            copy={`${new Date().getFullYear()} Aldridge, All Rights Reserved`}
                          />
                          <ServiceWorkerWrapper />
                        </div>
                      </Suspense>
                    </LocalizationProvider>
                  </BrowserRouter>
                </OfflineDataProvider>
              </UserProvider>
            </OfflineProvider>
          </RecoilRoot>
        </ThemeProvider>
      </StyledEngineProvider>,
      rootElement
    );
  } catch (err) {
    // eslint-disable-next-line
    console.log('RENDERING FAILED');
  }
};
renderApp();
// serviceWorkerRegistration.register();
