import React from 'react';
import {
  Card,
} from '@mui/material';

const TimeSummaryLegend = () => (
  <Card style={{ backgroundColor: 'white', width: '100%', paddingBottom: '4px' }}>
    <div style={{ textAlign: 'left', width: '100%', padding: '8px 8px 0px 8px' }}>
      <b style={{ marginLeft: '4px' }}>Legend:</b>
      <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
        <div style={{ backgroundColor: 'red', width: '20px', height: '20px', border: 'solid' }} />
        <div style={{ margin: '2px 0px 0px 6px' }}>Records with Overtime/Doubletime hours</div>
      </div>
      <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
        <div style={{ backgroundColor: 'darkorange', width: '20px', height: '20px', border: 'solid' }} />
        <div style={{ margin: '2px 0px 0px 6px' }}>Records with more than 8 Regular hours</div>
      </div>
      <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
        <div style={{ backgroundColor: 'blue', width: '20px', height: '20px', border: 'solid' }} />
        <div style={{ margin: '2px 0px 0px 6px' }}>Records with Holiday/Vacation hours</div>
      </div>
      <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
        <div style={{ backgroundColor: 'green', width: '20px', height: '20px', border: 'solid' }} />
        <div style={{ margin: '2px 0px 0px 6px' }}>Records with Sick Leave hours</div>
      </div>
      <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
        <div style={{ backgroundColor: 'darkturquoise', width: '20px', height: '20px', border: 'solid' }} />
        <div style={{ margin: '2px 0px 0px 6px' }}>Records with No Work Recorded or Less Than 8 Hours Verified</div>
      </div>
      <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
        <div style={{ backgroundColor: '#dbdd2e', width: '20px', height: '20px', border: 'solid' }} />
        <div style={{ margin: '2px 0px 0px 6px' }}>Records with No Work Recorded &amp; Hours</div>
      </div>
      <div style={{ padding: '4px 4px 4px 24px', width: 'fit-content', color: 'red', display: 'flex', flexDirection: 'row' }}>
        <div style={{ margin: '2px 0px 0px 6px' }}>Records with Invalid PICCs</div>
      </div>
    </div>
  </Card>
);


export default TimeSummaryLegend;