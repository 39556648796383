import React from 'react';
import PropTypes from 'prop-types';

const SignInButton = (props) => {
  const { mode, onClick, AuthType } = props;

  const setMode = () => {
    if (AuthType === 'Google') {
      return (mode === 'dark') ? 'googleSignInDark' : 'googleSignInLight';
    } if (AuthType === 'Microsoft') {
      return (mode === 'dark') ? 'microsoftSignInDark' : 'microsoftSignInLight';
    }
    return '';
  };

  return (
    <div className={setMode()} onClick={onClick} title={`${AuthType} Sign In Button`} aria-hidden="true" />
  );
};

SignInButton.propTypes = {
  mode: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  AuthType: PropTypes.string
};
SignInButton.defaultProps = {
  AuthType: "Google"
};

export default SignInButton;
