import React, { useEffect, useContext } from 'react';
import { Alert, Grid } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import
TimeSummaryList
  from '../components/TimeSummary/TimeSummaryList';
import { _CurrentDate, _HeaderTitle } from '../_Recoil/atoms';
import ErrorBoundary from '../utils/ErrorBoundary';

import { UserContext } from '../providers/UserProvider';
import { checkTime } from '../utils/checkLockout';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';

const TimeSummary = () => {
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  document.title = 'Time Summary';
  useEffect(() => setHeaderTitle('Weekly Time Summary'), []);

  const user = useContext(UserContext);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const forcedCutoff = checkTime(user, CurrentDate, true);

  return (
    <ErrorBoundary componentName='Time Summary'>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ paddingBottom: '8px', textAlign: 'center' }}>
            <h1>{document.title}</h1>
          </div>
          <DailyToolbar />
          <div style={{ padding: '8px' }} />
        </Grid>
        {forcedCutoff && (
          <Grid item xs={12}>
            <Alert severity='warning'>
              Due to Bank Events or Holidays, Time Entry is due by{' '}
              <strong>
                {dayjs(forcedCutoff).format('MM/DD/YY HH:mm A')} CST
              </strong>
              . Please have all time entered by this time. If you do not have
              time entered in the DJR by this time you will need to contact
              Payroll directly to make sure any missed time is entered.
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <TimeSummaryList />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

export default TimeSummary;
